
import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../components/header"
import "../components/layout.css"


import styled from "@emotion/styled";

const B2 = styled.div`
  background-color: black;
  background-size: 360px 360px, 360px 360px, 120px 120px, 120px 120px ;
  background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
  
background-image: linear-gradient(#ffffffad 2px, transparent 2px),
                  linear-gradient(90deg, #ffffffad 2px, transparent 2px),
                  linear-gradient(rgba(255,255,255,.3) 1px, transparent 1px),
                  linear-gradient(90deg, rgba(255,255,255,.3) 1px, transparent 1px);


  background-image: -webkit-linear-gradient(#ffffffad 1px, transparent 1px),
                  -webkit-linear-gradient(0, #ffffffad 1px, transparent 1px),
                  -webkit-linear-gradient(rgba(255,255,255,.3) 1px, transparent 1px),
                  -webkit-linear-gradient(0, rgba(255,255,255,.3) 1px, transparent 1px);

background-image: -moz-linear-gradient(#ffffffad 2px, transparent 2px),
                  -moz-linear-gradient(0, #ffffffad 2px, transparent 2px),
                  -moz-linear-gradient(rgba(255,255,255,.3) 1px, transparent 1px),
                  -moz-linear-gradient(0, rgba(255,255,255,.3) 1px, transparent 1px);

position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  `;


const Layout = ({ children }) => {
  const data =  useStaticQuery(graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `)
 
  return(
    <B2>

    <div>

      <main>{children}</main>
    </div>
    </B2>
  )
}

export default Layout
